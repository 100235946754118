body {
  font-family: Arial, sans-serif;
}

.app {
  background-color: #efefef;
  background: url('./assets/winter-bg.jpg');
  background-size: cover;
  background-position: bottom center;
  position: relative;
  overflow: hidden;
}

svg {
  max-width: 100%;
  max-height: 100vh;
  overflow: visible;
}

svg#santa {
  width: 100%;
  height: 100%;
}

svg.resetting {
  transition: top 1s, left 1s, transform 1s;
}

svg.snapped {
  transition: top 0.1s, left 0.1s, transform 0.1s;
}

/* Source */
#source svg {
  position: absolute;
}

/* #elements svg {
  transform: scale(0.2, 0.2);
  transition: transform 1s;
} */

#elements svg {
  display: none;
}

#elements .open svg,
#elements svg.dropped {
  display: block;
}

.show-category {
  font: normal 5px sans-serif;
  cursor: pointer;
}

.show-category:hover {
  font: bold 5px sans-serif;
  fill: #00393f;
}

#take-photo {
  cursor: pointer;
}

#take-photo:hover rect {
  fill: #dedc00;
}

.take-photo-label {
  font: bold 5px sans-serif;
}

.main-text {
  font: bold 7px sans-serif;
  fill: #00393f;
}

.small-text {
  font: normal 3px sans-serif;
  fill: #FFF;
  cursor: pointer;
}

a:hover .small-text {
  font: bold 3px sans-serif;
}

#target svg {
  position: absolute;
}

#share a:hover path {
  fill: #00393f;
}

svg.targetting > * {
  border: 1px dashed #000;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
  stroke: #000;
}

/* Drag CSS */

svg.dragging {
  opacity: 0.5;
}

.drag-over {
  border: dashed 3px red;
}

.portrait-only {
  display: none;
}

@media only screen and (orientation:portrait) {
  .portrait-only {
    display: block;
  }
}